import React, { PropsWithChildren, createContext, useState } from 'react';
import Currency from '~/types/Currency';
import CurrencyMap from '../utils/CurrencyMap';

type Props = PropsWithChildren<{
    currencyMap: CurrencyMap;
}>;

export const CurrencyConversionContext = createContext<CurrencyMap>(
    new CurrencyMap({}, Currency.EUR)
);

function CurrencyConversionProvider({ currencyMap, children }: Props) {
    return (
        <CurrencyConversionContext.Provider value={currencyMap}>
            {children}
        </CurrencyConversionContext.Provider>
    );
}

export default CurrencyConversionProvider;
