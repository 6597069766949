export default class CurrencyMap extends Map<string, number> {
    constructor(currencyRates: Record<string, number>, public readonly defaultCurrency: string) {
        super(Object.entries(currencyRates));
    }

    convert(amount: number, from: string, to?: string): number {
        const fromRate = this.get(from);
        const toRate = this.get(to ?? this.defaultCurrency);

        if (!toRate) {
            throw new Error(`Unknown currency ${to}`);
        }

        if (!fromRate) {
            throw new Error(`Unknown currency ${from}`);
        }

        return toRate / fromRate * amount;
    }
}
